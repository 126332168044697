

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'receiving-screen',
    components: {},
    props: {},
    setup(props, context) {
        return {};
    },
});

